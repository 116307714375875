import { CalendarToday, LocationSearching, PermIdentity, PhoneAndroid, Publish } from '@material-ui/icons';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import BadgeIcon from '@mui/icons-material/Badge';
import './user.css';
import { Link, useLocation } from "react-router-dom";
import UserUpdate from './UserUpdate';
import { Badge } from '@mui/material';
import { databaseLocation } from '../../dummyData';
import Axios from "axios";
import { useState } from 'react';
import { useEffect } from 'react';

export default function User() {

    const location = useLocation();
    const userId = location.pathname.split("/")[2];

    const [user, setUser] = useState({});

    useEffect( () => {

        var user = {};
        let isMounted = true;
        Axios.get(`${databaseLocation}/api/user/${userId}`).then( (response) => {
            if(isMounted) {
                if(!response.data.includes("not logged in")) {
                    setUser(response.data[0]);
                    user = response.data[0];
                }
            }
        }).then( () => {
            fetchImg(user);
        });
        
        return () => { 
            isMounted = false;
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    
    const fetchImg = (user) => {

        console.log("Fetching image..");

        Axios.get(databaseLocation+"/getImage/"+user.userId).then( (res) => {

            var arrayBufferView = new Uint8Array(res.data[0].image.data);
            var blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL( blob );
            user.avatar = imageUrl;
            
            var newUser = {};
            {Object.keys(user).map( (key, index) => {
                newUser[key] = user[key];
            })}

            setUser(newUser);
        })
    }

    const Button = ({type}) => {
        return <button className={"shopProductButton " + type}>{type}</button>
    }

  return (
      <div className="user">
          <div className="userTitleContainer">
              <h1 className="userTitle">Edit User</h1>
          </div>
          <div className="userContainer">
              <div className="userShow">
                  <div className="userShowTop">
                      <img src={user.avatar} alt="" className="userShowImg" />
                      <div className="userShowTopTitle">
                          <span className="userShowUsername">{user.firstname} {user.lastname}</span>
                          {/* <span className="userShowUserTitle">Lord Kommandat der Nachtwache</span> */}
                      </div>
                  </div>
                  <div className="userShowBottom">
                      <span className="userShowTitle">Account Details</span>
                      <div className="userShowInfo">
                        <PermIdentity className="userShowIcon" />
                        <span className="userShowInfoTitle">{user.user}</span>
                      </div>
                      <div className="userShowInfo">
                        <MilitaryTechIcon className="userShowIcon" />
                        <span className="userShowInfoTitle">{user.level}</span>
                      </div>
                      <span className="userShowTitle">Contact Details</span>
                      <div className="userShowInfo">
                        <BadgeIcon className="userShowIcon" />
                        <span className="userShowInfoTitle">{user.firstname} {user.lastname}</span>
                      </div>
                      <div className="userShowInfo">
                        {user.gender && user.gender.toLowerCase() === "male" && <MaleIcon className="userShowIcon" />}
                        {user.gender && user.gender.toLowerCase() === "female" && <FemaleIcon className="userShowIcon" />}
                        <span className="userShowInfoTitle">{user.gender}</span>
                      </div>
                      <div className="userShowInfo">
                        <LocationSearching className="userShowIcon" />
                        <span className="userShowInfoTitle">{user.status}</span>
                      </div>
                  </div>
              </div>
              <UserUpdate user={user} setUser={setUser} />
          </div>
      </div>
  )
}
