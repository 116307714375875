import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useEffect } from "react";
import { databaseLocation } from "../../dummyData";
import { useState } from "react";
import axios from "axios";

export default function FeaturedInfo() {

    const [InvoiceList, setInvoiceList] = useState([])
    const [TotalRevenue, setTotalRevenue] = useState([])
    const [MonthlyRevenue, setMonthlyRevenue] = useState(0)

    useEffect( async () => {

        await axios.get(`${databaseLocation}/api/invoice/getAll`).then( (response) => {
            console.log(response.data)
            setInvoiceList(response.data);
        });
    }, [])
    
    useEffect( () => {
        if(InvoiceList.length > 0) {
            calculateTotalRevenue();
            calculateMonthlyRevenue();
        }
    }, [InvoiceList])
    
    const calculateTotalRevenue = () => {

        let revenue = 0.0;
        InvoiceList.map( async (invoice, index) => {

            //Get InvoicePositions
            await axios.get(`${databaseLocation}/api/invoicePositions/get/${invoice.invoiceId}`).then( (response) => {
            
                const prices = response.data.map( (position) => {
                    revenue += position.Price;
                })
                Promise.all(prices);
            });
            setTotalRevenue(revenue);
        });
    }

    const calculateMonthlyRevenue = () => {

        let revenue = 0.0;
        InvoiceList.map( async (invoice) => {

            let invoiceDate = new Date(invoice.date);
            let todaysDateMinusThirty = new Date();
            todaysDateMinusThirty.setDate(todaysDateMinusThirty.getDate() - 30)
            // console.log("InvoiceDate: " + invoiceDate.getMonth());
            // console.log("todaysDateMinusThirty: " + todaysDateMinusThirty.getMonth());
            if(invoiceDate >= todaysDateMinusThirty) {

                //Get InvoicePositions
                await axios.get(`${databaseLocation}/api/invoicePositions/get/${invoice.invoiceId}`).then( (response) => {
                
                    const prices = response.data.map( (position) => {
                        revenue += position.Price;
                    })
                    Promise.all(prices);
                });
                setMonthlyRevenue(revenue);
            }
        });
    }

  return (
      <div className="flex gap-6">
        <div className="w-full mx-4 p-8 rounded-lg cursor-pointer shadow-[5px_5px_15px_-7px_#000000]">
            <span className="text-xl">Revenue</span>
            <div className="my-2.5 flex items-center">
                <span className="text-3xl font-semibold">{MonthlyRevenue} €</span>
                {/* <span className="flex items-center ml-5">
                    $-11.4 <ArrowDownward className="ml-1 text-sm text-red-500"/>
                </span> */}
            </div>
            <span className="text-sm text-gray-500">Last 30 days</span>
        </div>
        <div className="w-full p-8 rounded-lg cursor-pointer shadow-[5px_5px_15px_-7px_#000000]">
            <span className="text-xl">Revenue</span>
            <div className="my-2.5 flex items-center">
                <span className="text-3xl font-semibold">{TotalRevenue} €</span>
                {/* <span className="flex items-center ml-5">
                    $-11.4 <ArrowDownward className="ml-1 text-sm text-red-500"/>
                </span> */}
            </div>
            <span className="text-sm text-gray-500">In Total</span>
        </div>
        {/* <div className="flex-1 p-8 rounded-lg cursor-pointer shadow-[5px_5px_15px_-7px_#000000]">
            <span className="text-xl">Sales</span>
            <div className="my-2.5 flex items-center">
                <span className="text-3xl font-semibold">$2.415</span>
                <span className="flex items-center ml-5">
                    $-11.4 <ArrowDownward className="ml-1 text-sm text-red-500"/>
                </span>
            </div>
            <span className="text-sm text-gray-500">Compared to last month</span>
        </div> */}
      </div>
  );
}
