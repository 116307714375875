import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import { DeleteOutline, Search, Add, Remove } from '@material-ui/icons'
import { databaseLocation } from '../dummyData'
import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Axios from 'axios'
import Dropdown from '../components/Dropdown'

export default function ProductList() {
	const [articleList, setArticleList] = useState([])
	const [filteredList, setFilteredList] = useState([])
	const [searchText, setSearchText] = useState('')
	const [selectedFilters, setSelectedFilters] = useState([])

	const location = useLocation()

	useEffect(() => {
		let isMounted = true
		Axios.get(`${databaseLocation}/api/articles/getAll`)
			.then((response) => {
				if (isMounted) {
					setArticleList(response.data)
					setFilteredList(response.data)
					if (location.pathname.split('/').length > 2) {
						setSearchText(location.pathname.split('/')[2])
					}
				}
			})
			.catch((err) => {
				console.log(err)
			})

		return () => {
			isMounted = false
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const handleFilter = (value) => {
		if (value.length < 1) {
			setSelectedFilters([])
			setFilteredList(articleList)
		} else {
      if(!selectedFilters.includes(value)) {
        setSelectedFilters([...selectedFilters, value])
      }
      else {
        setSelectedFilters(selectedFilters.filter((option) => option != value))
      }
			let tempList = articleList
			if (value.includes('NotOnEbay')) {
				tempList = tempList.filter((article) => article.onEbay == 0)
			}
			if (value.includes('WithPicture')) {
				tempList = tempList.filter((article) => article.URL.length > 3)
			}
			if (value.includes('WithoutPicture')) {
				tempList = tempList.filter((article) => article.URL.length < 3)
			}
			if (value.includes('NoRegulator')) {
				tempList = tempList.filter(
					(article) => !article.articleType.toLowerCase().includes('regulator')
				)
			}
			if (value.includes('InStock')) {
				tempList = tempList.filter((article) => article.quantity > 0)
			}
			setFilteredList(tempList)
		}
	}

	/** Search Handler **/
	useEffect(() => {
		const searchTxt = searchText.toLowerCase().replaceAll(' ', '')
		setFilteredList(
			articleList.filter(
				(article) =>
					article.articleNumber.replaceAll(' ', '').toLowerCase().includes(searchTxt) ||
					article.oe.replaceAll(' ', '').toLowerCase().includes(searchTxt)
			)
		)
	}, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

	const columns = [
		{ field: 'articleType', headerName: 'Type', minWidth: 150, flex: 1 },
		{ field: 'brand', headerName: 'Brand', minWidth: 100, flex: 1 },
		{ field: 'power', headerName: 'Power', minWidth: 80, flex: 0.5 },
		{ field: 'storagePlace', headerName: 'Place', minWidth: 120, flex: 0.8 },
		{ field: 'quantity', headerName: 'Qty', minWidth: 80, flex: 0.5 },
		{ field: 'articleNumber', headerName: 'Article', minWidth: 150, flex: 1 },
		{ field: 'manufacturer', headerName: 'Manufacturer', minWidth: 120, flex: 0.8 },
		{ field: 'ebayPlus', headerName: 'Price', minWidth: 100, numeric: true, flex: 0.8 },
		{ field: 'onEbay', headerName: 'onEbay', minWidth: 100, numeric: true, flex: 0.8 },
		{
			field: 'URL',
			headerName: 'Image',
			minWidth: 80,
			flex: 0.8,
			renderCell: (params) => {
				return (
					<div>
						{params.row.URL.length > 3 && (
							<img className='size-14 rounded-full object-cover' src={params.row.URL} alt='' />
						)}
					</div>
				)
			},
		},
		{
			field: 'action',
			headerName: 'Actions',
			minWidth: 150,
			flex: 1.5,
			renderCell: (params) => {
				return (
					<div className="flex gap-2 items-center">
						<button
							className='p-2 rounded-full bg-green-500 text-white'
							onClick={() => updateQuantityHandler(params.row, 1)}>
							<Add />
						</button>
						<button
							className='p-2 rounded-full bg-red-500 text-white'
							onClick={() => updateQuantityHandler(params.row, -1)}>
							<Remove />
						</button>
						<Link to={'/shop/' + params.row.articleId}>
							<Search />
						</Link>
					</div>
				)
			},
		},
	]

	const filterOptions = ['NotOnEbay', 'WithPicture', 'WithoutPicture', 'NoRegulator', 'InStock']

	const updateQuantityHandler = async (article, change) => {
		const newQuantity = article.quantity + change
		if (newQuantity < 0) return

		await Axios.put(`${databaseLocation}/api/updateQuantity`, {
			quantity: newQuantity,
			articleId: article.articleId,
			clientAccessToken: 'lima454#',
		})
			.then((res) => {
				article.quantity = newQuantity
				updateArticleInList(article)
			})
			.catch((e) => {
				console.log(e)
			})
	}

	const updateArticleInList = (article) => {
		const newList = articleList.map((item) => {
			if (item.articleId === article.articleId) {
				const updatedItem = {
					...item,
					quantity: article.quantity,
				}
				return updatedItem
			}
			return item
		})
		setArticleList(newList)
	}

	return (
		<div className='w-full m-4'>
			<div className='flex flex-col md:flex-row gap-4 mb-4'>
				{/* Search Input */}
				<input
					type='text'
					placeholder='Search..'
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					className='border border-gray-300 rounded p-2 w-full md:w-auto'
				/>

				{/* Filter Dropdown */}
				<Dropdown
					options={filterOptions}
					selectedOptions={selectedFilters}
					onSelect={handleFilter}
				/>
			</div>

			{/* Responsive Data Grid */}
			<Box
				sx={{
					width: '100%',
					height: 'calc(100vh - 200px)',
					'& .MuiDataGrid-root': {
						border: 'none', // Remove borders for a cleaner look
					},
					'& .MuiDataGrid-cell': {
						whiteSpace: 'normal',
						wordWrap: 'break-word',
					},
					'& .MuiDataGrid-columnHeaders': {
						backgroundColor: '#f7f7f7',
					},
					overflowX: 'auto', // Enable horizontal scrolling on smaller screens
				}}
			>
				<DataGrid
					autoHeight
					rows={filteredList}
					getRowId={(row) => row.articleId}
					columns={columns}
					pageSize={15}
					rowsPerPageOptions={[15]}
					disableSelectionOnClick
					columnBuffer={2} // Optimizes performance and responsiveness
				/>
			</Box>
		</div>
	)
}
