import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import "./profile.css";
import { useQuery, useMutation, gql } from "@apollo/client";
import { databaseLocation } from "../../dummyData";
import Axios from "axios";
import { useEffect, useState } from "react";
import UserUpdate from "../user/UserUpdate";

export default function Profile({ user, setUser }) {
  const [myImg, setMyImg] = useState();

  const avatarUploadHandler = async (event) => {
    console.log("IMG: " + event.target.value);
    console.log("File Name: " + event.target.files[0].name);
    console.log("File Type: " + event.target.files[0].type);
    console.log("File Size: " + event.target.files[0].size);

    // const response = await fetch(databaseLocation+"/cors", { mode: 'cors' });
    // console.log("Res: " + response);

    let formData = new FormData();
    formData.append("pic", event.target.files[0]);
    formData.append("userId", user.userId);
    await Axios.post(databaseLocation + "/replaceImage", formData, {
      // name: event.target.files[0].name,
      // file_type: event.target.files[0].type,
      // file_size: event.target.files[0].size,
      // userId: 47,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then(async (res) => {
      console.log("Res2: " + (await res.data));
      fetchImg();
    });
  };

  const fetchImg = () => {
    console.log("Fetching image..");

    Axios.get(databaseLocation + "/getImage/" + user.userId).then((res) => {
      var arrayBufferView = new Uint8Array(res.data[0].image.data);
      var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(blob);
      user.avatar = imageUrl;

      var newUser = {};
      {
        Object.keys(user).map((key, index) => {
          newUser[key] = user[key];
        });
      }

      console.log(newUser);
      setUser(newUser);
    });
  };

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Profil</h1>
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <img src={user.avatar} alt="avatar" className="userShowImg" />

            <div className="userShowTopTitle">
              <span className="userShowUsername">
                {user ? `${user.firstname} ${user.lastname}` : "User"}
              </span>
              {/* <span className="userShowUserTitle">Lord Kommandat der Nachtwache</span> */}
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Kontakt Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">
                {user ? `${user.firstname} ${user.lastname}` : "User"}
              </span>
            </div>
            {/* <div className="userShowInfo">
                        <CalendarToday className="userShowIcon" />
                        <span className="userShowInfoTitle">10.12.1981</span>
                      </div> */}
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitle">+49 176 245742</span>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitle">jonsnow@hotmail.com</span>
            </div>
            {/* <div className="userShowInfo">
                        <LocationSearching className="userShowIcon" />
                        <span className="userShowInfoTitle">New York | USA</span>
                      </div> */}
          </div>
        </div>
        {user && <UserUpdate user={user} setUser={setUser} />}
      </div>
    </div>
  );
}
