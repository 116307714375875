import { useEffect, useState } from 'react';
import { databaseLocation } from "../dummyData";
import Axios from "axios";
import ArticleTypeChart from './ArticleTypeChart';
import ManufacturerChart from './ManufacturerChart';
import BrandChart from './BrandChart';
import ConditionChart from './ConditionChart';

export default function Charts() {
  const [articleList, setArticleList] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await Axios.get(`${databaseLocation}/api/articles/getAll`);
        setArticleList(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };
    fetchArticles();
  }, []);

  if (articleList.length === 0) {
    return (
      <div className="flex justify-center items-center p-6 mt-8">
        <h3 className="text-xl font-medium text-gray-700 animate-pulse">
          Loading Charts...
        </h3>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <ArticleTypeChart articleList={articleList} />
      <ManufacturerChart articleList={articleList} />
      <BrandChart articleList={articleList} />
      <ConditionChart articleList={articleList} />
    </div>
  );
}
