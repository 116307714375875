import Topbar from "./components/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./Home";
import Shop from "./pages/shop/Shop";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import ProductList from "./pages/ProductList";
import Product from "./pages/product/Product";
import NewProduct from "./pages/newProduct/NewProduct";
import Login from "./pages/login/Login";
import Profile from "./pages/profile/profile";
import { databaseLocation } from "./dummyData";
import Footer from "./components/footer/Footer";
import NotLoggedIn from "./pages/notLoggedIn/NotLoggedIn";
import Imprint from "./pages/Information/Imprint";
import DataProtection from "./pages/Information/DataProtection";
import Interesting from "./pages/Information/Interesting";
import Contact from "./pages/Information/Contact";
import CreateInvoice from "./pages/invoice/CreateInvoice/CreateInvoice";
import EditInvoice from "./pages/invoice/EditInvoice/EditInvoice";
import InvoiceList from "./pages/invoice/InvoiceList/InvoiceList";
import LevelToLow from "./pages/notLoggedIn/NotLoggedIn";
import Orders from "./pages/orders/Orders";
import Cart from "./Cart";
import LoginRegister from "./popups/loginRegister/loginRegister";
import UserCreation from "./pages/register/userCreation/userCreation";
import Register from "./pages/register/Register";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [ClientAccessToken, setClientAccessToken] = useState({});
  const [language, setLanguage] = useState("german");

  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <Topbar
          loggedIn={loggedIn}
          user={user}
          setLoggedIn={setLoggedIn}
          setUser={setUser}
          setShowLoginPopup={setShowLoginPopup}
        />

        <div className="w-full mt-2 mx-auto px-4 sm:px-6 lg:px-8">
          <Sidebar loggedIn={loggedIn} />

          <Routes>
            <Route path="/" element={<Shop />} />
            <Route
              path="/login"
              element={
                <Login
                  loggedIn={loggedIn}
                  setLoggedIn={setLoggedIn}
                  setUser={setUser}
                  desiredPath={window.location.pathname}
                  databaseLocation={databaseLocation}
                />
              }
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/home"
              element={loggedIn ? <Home /> : <NotLoggedIn />}
            />
            <Route path="/shop" element={<Shop />} />
            <Route
              path="/shop/:articleId"
              element={<Product user={user} setUser={setUser} />}
            />
            <Route
              path="/users"
              element={loggedIn ? <UserList /> : <NotLoggedIn />}
            />
            <Route
              path="/users/:userId"
              element={loggedIn ? <User /> : <NotLoggedIn />}
            />
            <Route
              path="/newUser"
              element={
                loggedIn ? (
                  user.level >= 5 ? (
                    <NewUser />
                  ) : (
                    <LevelToLow />
                  )
                ) : (
                  <NotLoggedIn />
                )
              }
            />
            <Route
              path="/products"
              element={
                loggedIn ? (
                  <ProductList />
                ) : (
                  <Login
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setUser={setUser}
                    desiredPath={window.location.pathname}
                    databaseLocation={databaseLocation}
                  />
                )
              }
            />
            <Route
              path="/products/:searchText"
              element={
                loggedIn ? (
                  <ProductList />
                ) : (
                  <Login
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setUser={setUser}
                    desiredPath={window.location.pathname}
                    databaseLocation={databaseLocation}
                  />
                )
              }
            />
            <Route
              path="/invoice"
              element={
                loggedIn ? (
                  <InvoiceList />
                ) : (
                  <Login
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setUser={setUser}
                    desiredPath={window.location.pathname}
                    databaseLocation={databaseLocation}
                  />
                )
              }
            />
            <Route
              path="/invoice/create"
              element={
                loggedIn ? (
                  <CreateInvoice />
                ) : (
                  <Login
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setUser={setUser}
                    desiredPath={window.location.pathname}
                    databaseLocation={databaseLocation}
                  />
                )
              }
            />
            <Route
              path="/invoice/:invoiceId"
              element={
                loggedIn ? (
                  <EditInvoice />
                ) : (
                  <Login
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setUser={setUser}
                    desiredPath={window.location.pathname}
                    databaseLocation={databaseLocation}
                  />
                )
              }
            />
            <Route
              path="/products/:productId"
              element={loggedIn ? <Product /> : <NotLoggedIn />}
            />
            <Route
              path="/newProduct"
              element={loggedIn ? <NewProduct /> : <NotLoggedIn />}
            />
            <Route
              path="/profile"
              element={loggedIn ? <Profile user={user} setUser={setUser} /> : <NotLoggedIn />}
            />
            <Route
              path="/cart"
              element={
                loggedIn ? (
                  <Cart user={user} setUser={setUser} />
                ) : (
                  <LoginRegister
                    setShowLoginPopup={setShowLoginPopup}
                    setUser={setUser}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                  />
                )
              }
            />
            <Route
              path="/orders"
              element={loggedIn ? <Orders /> : <NotLoggedIn />}
            />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/dataProtection" element={<DataProtection />} />
            <Route path="/interesting" element={<Interesting />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>

        {showLoginPopup && (
          <LoginRegister
            setShowLoginPopup={setShowLoginPopup}
            setUser={setUser}
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
          />
        )}

        <Footer />
      </div>
    </Router>
  );
}

export default App;
