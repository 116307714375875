import { Link } from 'react-router-dom';
import NoPic from "../nopic.png";
import Button from "./button/Button";

export default function ShopProductsListing({ article, isCartView }) {
  const imageClasses = isCartView 
    ? "w-16 md:w-24 h-16 md:h-24 object-contain" 
    : "w-32 md:w-48 h-32 md:h-48 object-contain";

  const titleClasses = isCartView
    ? "text-sm font-medium text-gray-900"
    : "text-lg font-medium text-gray-900";

  const detailsClasses = isCartView
    ? "text-xs text-gray-600 mt-1 space-x-3"
    : "text-base text-gray-600 mt-2 space-x-4";

  return (
    <div className={`flex flex-col ${isCartView ? 'cart-view' : 'p-4 hover:bg-gray-50/50 transition-all duration-200'}`}>
      <Link 
        to={"/shop/"+article.articleId} 
        className="group block no-underline text-gray-900 hover:no-underline"
      >
        <div className={titleClasses}>
          {article.brand + " " + 
           article.articleType.replace("Alternator", "Lichtmaschine") + " " + 
           article.power.replace(" ", "") + " " + 
           article.articleNumber + " " + 
           article.manufacturer}
          
          <div className="flex items-center gap-4 mt-3">
            <div className="bg-white p-2 rounded-lg">
              {article.URL && article.URL.length > 3 ? (
                <img 
                  src={article.URL} 
                  alt={article.articleType} 
                  className={`${imageClasses} transition-transform duration-200 group-hover:scale-105`}
                /> 
              ) : (
                <img 
                  src={NoPic} 
                  alt="No image available" 
                  className={`${imageClasses} transition-transform duration-200 group-hover:scale-105`}
                />
              )}
            </div>
            <div className="flex flex-col gap-2">
              <span className={`font-medium ${isCartView ? 'text-base' : 'text-xl'}`}>
                {article.ebayPlus}
                <span className="ml-1">€</span>
              </span>
              {article.quantity > 0 ? (
                <Button type="In Stock" text="Verfügbar" />
              ) : (
                <Button type="Out of Stock" text="Ausverkauft" />
              )}
            </div>
          </div>
          
          <div className={detailsClasses}>
            {article.volt && (
              <span className="inline-block">Volt: {article.volt}</span>
            )}
            {article.grooves && (
              <span className="inline-block">
                {article.articleType === "Lichtmaschine" ? "Grooves:" : "Theeth:"} {article.grooves}
              </span>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

ShopProductsListing.defaultProps = {
  isCartView: false
};
